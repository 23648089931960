 import React from "react";
import {Link} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";

// Import images
 import dashboard from "../../assets/images/dashboard.png";


 const Section = () => {
    return (
        <React.Fragment>

            <section
                className="bg-half-50 d-table w-100 overflow-hidden"
                id="home"
            >
                <Container>
                    <Row className="align-items-center pt-5">
                        <Col lg={6} md={6}>
                            <div className="title-heading">
                                <h1 className="heading mb-3">
                                    einfach selber buchen - mit intelligenter Unterstützung
                                </h1>
                                <p className="para-desc text-muted">
                                    Revolutionieren Sie Ihre Finanzbuchhaltung: Unsere cloudbasierte Lösung mit
                                    fortschrittlicher Technologie macht Buchhaltung einfach, zuverlässig und effizient.
                                    Optimal für KMU, Vereine, Einzelfirmen und Privatpersonen - Ihre Finanzen,
                                    optimiert für die Zukunft.
                                </p>
                                <p className="para-desc text-muted">
                                    <b>Neu:</b> Erstellen Sie Offerten, Rechnungen und Mahnungen (mit Schweizer QR-Code)
                                    und verwalten Sie Ihre Artikel direkt in unserem System (inkl. Bankabgleich).
                                </p>
                                <p className="para-desc text-muted">
                                    Überzeugen Sie sich selbst und melden Sie sich für die kostenlose Basisversion
                                    an.
                                </p>
                                <div className="mt-4 pt-2">
                                    <a
                                        href="/app-new/"
                                        className="btn btn-primary"
                                    >
                                        Jetzt kostenlos registrieren und gleich loslegen
                                    </a>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="position-relative">
                                <div className="bg-saas-shape position-relative">
                                    <img src={dashboard} className={'dashboard-img'}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Section;
