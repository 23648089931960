// React Basic and Bootstrap
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row,} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";

//Import Icons
import FeatherIcon from "feather-icons-react";
// import images
import user02 from "../../assets/images/user/02.jpg";

const Registration = () => {

    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [processingVisible, setProcessingVisible] = useState(false);
    const [sent, setSent] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: "",
            password: "",
            passwordRepeated: "",
            confirm: false
        },
        validationSchema: Yup.object({
            email: Yup.string("Bitte geben Sie eine E-Mail-Adresse ein").email("Geben Sie eine gültige E-Mail-Adresse ein").required("E-Mail ist erforderlich"),
            password: Yup.string()
                .required("Bitte geben Sie ein Passwort ein"),

            confirm: Yup.boolean().oneOf([true], "Freischaltung durchführen")
        }),

        onSubmit: (values) => {
            var formData = new URLSearchParams();

            if (values.confirm) {
                values.confirm = "true";
            } else {
                values.confirm = "false";
            }

            for (var key in values) {
                if (values.hasOwnProperty(key)) {
                    formData.append(key, values[key]);
                }
            }

            sendPostRequest(formData);
        }
    });

    let sendPostRequest = function (formData) {
        setSent(true);
        setProcessingVisible(true);

        fetch("/rest/api/v1/migrate/", {
            method: "POST",
            redirect: "manual",
            body: formData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then((response) => {
                setProcessingVisible(false);
                if (response.ok) {
                    setSuccessVisible(true);
                    setSent(true);
                } else {
                    setErrorVisible(true);
                    setSent(true);
                }
            })
            .catch((error) => {
                setProcessingVisible(false);
                setErrorVisible(true);
                setSent(true);
            });
    }

    return (
        <React.Fragment>
            {/* Full-page overlay with spinner */}
            {processingVisible && (
                <div style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                }}>
                    <div className="spinner-border text-light" role="status"
                         style={{width: "3rem", height: "3rem"}}></div>
                    <div style={{
                        marginTop: "10px", // Spacing between spinner and text
                        color: "white",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        textAlign: "center"
                    }}>
                        Bitte haben Sie etwas Geduld, die Freischaltung läuft...
                    </div>

                </div>
            )}

            <section className="cover-user">
                <Container fluid className="px-0">
                    <Row className="g-0 position-relative">
                        <Col lg={4} xs={{order: 2}} className="cover-my-30 ">
                            <div className="cover-user-img d-flex align-items-center">
                            <Row>
                                    <Col xs={12}>
                                        <Card
                                            className="login_page border-0"
                                            style={{zIndex: 1}}
                                        >
                                            <CardBody className="p-0">
                                                <h4 className="card-title text-center">Das neue fibu3 ist da.</h4>
                                                <h5 className="card-title text-center">Zugang freischalten</h5>

                                                <div id="message"></div>

                                                <Alert
                                                    color="primary"
                                                    isOpen={processingVisible}
                                                    toggle={() => {
                                                        setProcessingVisible(!processingVisible);
                                                    }}
                                                >
                                                    Die Freischaltung ist in Bearbeitung. Dies kann bis zu 5 Minuten dauern, bitte haben Sie etwas Geduld.
                                            </Alert>

                                                <Alert
                                                    color="primary"
                                                    isOpen={successVisible}
                                                    toggle={() => {
                                                        setSuccessVisible(!successVisible);
                                                    }}
                                                >
                                                    Der Benutzer wurde erfolgreich freigeschaltet. Sie können sich nun über
                                                    die neue verbesserte Anmeldemaske anmelden.
                                                </Alert>
                                                <Alert
                                                    color="danger "
                                                    isOpen={errorVisible}
                                                    toggle={() => {
                                                        setErrorVisible(!errorVisible);
                                                    }}
                                                >
                                                    Etwas ist schief gegangen. Versuchen Sie es später oder senden Sie
                                                    uns eine Email.
                                                </Alert>

                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    className="login-form mt-4"
                                                >
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" for="email">
                                                                    Email{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <div className="form-icon position-relative">
                                                                    <i>
                                                                        <FeatherIcon
                                                                            icon="mail"
                                                                            className="fea icon-sm icons"
                                                                        />
                                                                    </i>
                                                                </div>
                                                                <Input
                                                                    type="text"
                                                                    disabled={sent}
                                                                    className="form-control ps-5"
                                                                    name="email"
                                                                    id="email"
                                                                    placeholder="Email"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.email || ""}
                                                                    invalid={
                                                                        validation.touched.email && validation.errors.email ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.email && validation.errors.email ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.email}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        <Col md={12}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" for="password">
                                                                    Paswort{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <div className="form-icon position-relative">
                                                                    <i>
                                                                        <FeatherIcon
                                                                            icon="lock"
                                                                            className="fea icon-sm icons"
                                                                        />
                                                                    </i>
                                                                </div>
                                                                <Input
                                                                    type="password"
                                                                    disabled={sent}
                                                                    className="form-control ps-5"
                                                                    name="password"
                                                                    id="password"
                                                                    placeholder="Passwort"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.password || ""}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>


                                                        <Col md="12">
                                                            <div className="mb-3">
                                                                <div className="form-check">
                                                                    <Input
                                                                        type="checkbox"
                                                                        disabled={sent}
                                                                        className="form-check-input"
                                                                        id="confirm"
                                                                        name="confirm"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.confirm || false}
                                                                        invalid={
                                                                            validation.touched.confirm && validation.errors.confirm ? true : false
                                                                        }
                                                                    />
                                                                    <Label
                                                                        className="form-check-label"
                                                                        for="confirm"
                                                                    >Benutzer freischalten</Label>
                                                                    {validation.touched.confirm && validation.errors.confirm ? (
                                                                        <FormFeedback
                                                                            type="invalid">{validation.errors.confirm}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="d-grid">
                                                                <Button color="primary"
                                                                        disabled={sent}>
                                                                    Benutzer freischalten
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col
                            lg={8}
                            className="offset-lg-4 padding-less img order-1"
                            style={{backgroundImage: `url(${user02})`}}
                        ></Col>
                    </Row>
                </Container>
            </section>

        </React.Fragment>
    );
}
export default Registration;
